@import '../../styles/theme.scss';

.index-section--flush {
  @include mobile() {
    margin:$spacing-unit * 12 0;
  }
}
// hero image
.hero_button_container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 4;
  height: 100%;
  position: relative;
  padding-bottom: $spacing-unit * 25;

  @include touch() {
    flex-direction: column;
    padding: 0;
    justify-content: flex-end;
    transform: translateY(-54px);
  }
  .button {
    margin-right: $spacing-unit * 5;
    font-size: 24px;
    line-height: 1;
    min-width: 315px;

    @include touch() {
      margin-right: 0;
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      min-width: auto;
      font-size: 18px;
    }
  }
}

/*===== hero-video.liquid =====*/
.hero__text-wrap--absolute {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 50%;
    left: 0;
    z-index: 3;
    //background: transparent linear-gradient(180deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;
  }
}

//featured-collections.liquid
.featured-collections-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @include touch() {
    grid-template-columns: repeat(2, 1fr);
  }
}

//featured-collection-switcher.liquid
.collection-switcher-page-width {
  @include mobile() {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
.collection-switcher__collection  {
  padding-right: $spacing-unit * 2.5;

  @include touch()  {
    padding-right: 0;
  }
}

